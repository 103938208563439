<template>
  <CreateUpdateTemplate
    :customClass="'project-create create-form'"
    :routePreventDialog="routePreventDialog"
    v-if="getPermission('opportunity:create')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        v-if="pageLoading"
        class="custom-skeleton custom-skeleton-full-width"
        type="button"
      ></v-skeleton-loader>
      <h1 v-else class="form-title d-flex">
        Opportunity For
        <div
          v-if="vProjectId"
          class="ml-3 form-title-link cursor-pointer text-h5"
          v-on:click="openDialog('project')"
        >
          {{ vProject?.name }}
        </div>
        <template v-else>
          <div
            class="ml-3 form-title-link cursor-pointer text-h5"
            v-on:click="openDialog('project')"
          >
            Select a Project
            <v-icon large color="cyan">mdi-account-check-outline</v-icon>
          </div>
          <div class="mx-3">OR</div>
          <div
            class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
            @click="addProjectDialog = true"
          >
            Create a new Project
            <v-icon large color="cyan darken-4">mdi-plus-circle-outline</v-icon>
          </div>
        </template>
        <!-- <span
          class="m-0 form-title-create-link pl-2"
          :class="{
            'custom-nowrap-ellipsis': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject) || isDuplicateMode"
            >mdi-plus-circle-outline</v-icon
          >
        </span> -->
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="validateJob()"
        color="cyan"
      >
        Save Opportunity
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="projectForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="height: 90vh; position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-container fluid>
                  <div>
                    <label class="font-weight-700 font-size-16 required"
                      >Opportunity Name
                    </label>
                    <!-- <v-text-field
                      v-model.trim="opportunityCreate.name"
                      dense
                      filled
                      color="cyan"
                      label="Opportunity Name"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      solo
                      flat
                      :rules="[
                        validateRules.required(
                          opportunityCreate.name,
                          'Opportunity Name'
                        ),
                        validateRules.required(
                          opportunityCreate.name,
                          'Opportunity Name'
                        ),
                        validateRules.minLength(
                          opportunityCreate.name,
                          'Opportunity Name',
                          1
                        ),
                        validateRules.maxLength(
                          opportunityCreate.name,
                          'Opportunity Name',
                          100
                        ),
                      ]"
                    ></v-text-field> -->
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          opportunityCreate.opportunity_name,
                          'Opportunity Name'
                        ),
                        validateRules.maxLength(
                          opportunityCreate.opportunity_name,
                          'Opportunity Name',
                          50
                        ),
                      ]"
                      :validationField="{
                        url_type: 'opportunity',
                        filter_type: 'opportunity',
                        field: 'opportunity_name',
                      }"
                      id="display-name"
                      placeholder="Name"
                      counter="50"
                      :parent-id="opportunityCreate.id"
                      :current-id="opportunityCreate.id"
                      show-dropdown
                      v-model="opportunityCreate.opportunity_name"
                      hideTopMargin
                    ></TextValidateInput>
                  </div>
                  <div class="py-2">
                    <label for="project" class="required">Company</label>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      id="Customer"
                      :items="list"
                      :disabled="formLoading"
                      :loading="formLoading"
                      v-model="opportunityCreate.customer"
                      placeholder="Select Company"
                      :rules="[
                        validateRules.required(
                          opportunityCreate.customer,
                          'Company'
                        ),
                      ]"
                      solo
                      flat
                      item-color="cyan"
                      item-text="display_name"
                      item-value="id"
                      return-object
                      hide-details
                      @change="selectCustomer($event)"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="'No Company Found.'"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>

                  <div class="py-2">
                    <TextAreaField
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      v-model="opportunityCreate.description"
                      placeholder="Description"
                      solo
                      flat
                      row-height="25"
                      counter="250"
                      :rules="[
                        validateRules.minLength(
                          opportunityCreate.description,
                          'Description',
                          1
                        ),
                        validateRules.maxLength(
                          opportunityCreate.description,
                          'Description',
                          200
                        ),
                      ]"
                    />
                  </div>
                  <div>
                    <v-layout class="my-1 bg-quo">
                      <v-flex md6 class="ml-2">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Billing Address</label>
                              <!-- v-if="customer && customer.id" -->
                              <!-- v-on:click="customerBillingDialog = true" -->
                              <!-- <v-icon
                                v-on:click="openDialog('billing')"
                                small
                                class="ml-3"
                                color="cyan"
                                >mdi-pencil</v-icon
                              > -->
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <!-- <div
                                v-if="billing && billing.property_name"
                                style="
                                  font-weight: 600 !important;
                                  color: black;
                                "
                              >
                                {{ billing.property_name }}
                              </div> -->
                              <template v-if="vBilling?.id">
                                <span
                                  v-if="vBilling && vBilling.property_address"
                                >
                                  <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                                >
                                <template>
                                  <div
                                    class="custom-flex-d"
                                    v-if="vBilling.property_name"
                                  >
                                    <b>
                                      <v-icon
                                        style="
                                          font-size: 18px;
                                          margin-top: -3px !important;
                                        "
                                        color="#000"
                                        class="cmt-2"
                                      >
                                        mdi-home-map-marker
                                      </v-icon>
                                    </b>
                                    <span
                                      >{{ vBilling.property_name }}<br
                                    /></span>
                                  </div>
                                  <div v-else>
                                    <em class="text-muted">
                                      <v-icon
                                        style="
                                          font-size: 18px;
                                          margin-top: -3px !important;
                                        "
                                        color="#000"
                                        class="cmt-2"
                                      >
                                        mdi-home-map-marker
                                      </v-icon>
                                      no Site Name</em
                                    >
                                  </div>
                                </template>

                                <template>
                                  <div class="custom-flex-d">
                                    <b>
                                      <v-icon
                                        style="
                                          font-size: 18px;
                                          margin-top: -3px !important;
                                        "
                                        color="#000"
                                        class="cmt-2"
                                      >
                                        mdi-map-marker
                                      </v-icon>
                                    </b>
                                    <span>
                                      <template v-if="vBilling.unit_no"
                                        >{{ vBilling.unit_no }},</template
                                      >
                                      <template v-if="vBilling.street_1"
                                        >{{ vBilling.street_1 }},</template
                                      >
                                      {{ vBilling.street_2 }}
                                      <br />
                                      {{ vBilling.country }}
                                      <template
                                        v-if="
                                          vBilling.zip_code &&
                                          vBilling.zip_code != '000000'
                                        "
                                      >
                                        {{ vBilling.zip_code }}
                                      </template>
                                    </span>
                                  </div>
                                </template>
                              </template>
                              <template v-else-if="vCustomerId">
                                <p class="mb-0 red--text">
                                  Please select billing location
                                </p>
                              </template>
                              <template v-else>
                                <p class="mb-0 red--text">
                                  Please select Company
                                </p>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details</label>

                              <!--    <v-icon
                                class="ml-3"
                                v-on:click="openDialog('billing-contact')"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              > -->
                            </td>
                          </tr>

                          <template v-if="vBillingContact?.id">
                            <tr
                              v-if="
                                vBillingContact && vBillingContact?.display_name
                              "
                            >
                              <td class="py-0">
                                <label>{{
                                  vBillingContact?.display_name
                                }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label
                                  v-if="
                                    vBillingContact &&
                                    vBillingContact.primary_phone
                                  "
                                  >{{ vBillingContact.primary_phone }}</label
                                >
                                <em v-else class="text-muted">
                                  no phone number</em
                                >
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label
                                  v-if="
                                    vBillingContact &&
                                    vBillingContact.primary_email
                                  "
                                  >{{ vBillingContact.primary_email }}</label
                                >
                                <em v-else class="text-muted"> no email</em>
                              </td>
                            </tr>
                          </template>
                          <template v-else-if="vCustomerId">
                            <p class="mb-0 red--text">
                              Please select contact person
                            </p>
                          </template>
                          <template v-else>
                            <tr>
                              <td class="font-weight-600 pt-0">
                                <p class="mb-0 red--text">
                                  Please select company
                                </p>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-flex>
                      <v-flex md6 class="mr-2 custom-border-right">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Site Location</label>
                              <!--  <v-icon
                                class="ml-3"
                                v-on:click="openDialog('property')"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              > -->
                              <!--  <v-icon
                                class="ml-3"
                                v-on:click="routeToServiceHistory()"
                                color="cyan"
                                small
                                >mdi-history</v-icon
                              > -->
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <!-- <div
                              v-if="property && property.property_name"
                                style="
                                  font-weight: 600 !important;
                                  color: black;
                                "
                              >
                                {{ property.property_name }}
                              </div> -->

                              <template v-if="vProperty?.id">
                                <span
                                  v-if="vProperty && vProperty.property_address"
                                >
                                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                >
                                <p
                                  class="mr-2"
                                  v-if="vProperty && vProperty.property_address"
                                >
                                  <template>
                                    <div
                                      class="custom-flex-d"
                                      v-if="vProperty?.property_name"
                                    >
                                      <b>
                                        <v-icon
                                          style="
                                            font-size: 18px;
                                            margin-top: -3px !important;
                                          "
                                          color="#000"
                                          class="cmt-2"
                                        >
                                          mdi-home-map-marker
                                        </v-icon>
                                      </b>
                                      <span
                                        >{{ vProperty?.property_name }}<br
                                      /></span>
                                    </div>
                                    <div v-else>
                                      <em class="text-muted">
                                        <v-icon
                                          style="
                                            font-size: 18px;
                                            margin-top: -3px !important;
                                          "
                                          color="#000"
                                          class="cmt-2"
                                        >
                                          mdi-home-map-marker
                                        </v-icon>
                                        no Site Name</em
                                      >
                                    </div>
                                  </template>

                                  <template>
                                    <div class="custom-flex-d">
                                      <b>
                                        <v-icon
                                          style="
                                            font-size: 18px;
                                            margin-top: -3px;
                                          "
                                          color="#000"
                                          class="cmt-2"
                                        >
                                          mdi-map-marker
                                        </v-icon>
                                      </b>
                                      <span>
                                        <template v-if="vProperty?.unit_no"
                                          >{{ vProperty?.unit_no }},</template
                                        >
                                        <template v-if="vProperty?.street_1"
                                          >{{ vProperty?.street_1 }},</template
                                        >
                                        {{ vProperty?.street_2 }}
                                        <br />
                                        {{ vProperty?.country }}
                                        <template
                                          v-if="
                                            vProperty?.zip_code &&
                                            vProperty?.zip_code != '000000'
                                          "
                                        >
                                          {{ vProperty?.zip_code }}
                                        </template>
                                      </span>
                                    </div>
                                  </template>
                                </p>
                              </template>
                              <template v-else-if="vCustomerId">
                                <p class="mb-0 red--text">
                                  Please select service location
                                </p>
                              </template>
                              <template v-else>
                                <p class="mb-0 red--text">
                                  Please select Company
                                </p>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details </label>
                              <v-chip
                                v-if="
                                  property_contact_person &&
                                  property_contact_person.tenant
                                "
                                small
                                color="green white--text"
                                label
                                class="ml-2 tenant-small-chip"
                                >Tenant</v-chip
                              >
                              <!--    <v-icon
                                class="ml-3"
                                v-on:click="openDialog('property-contact')"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              > -->
                            </td>
                          </tr>
                          <template v-if="vPropertyContact?.id">
                            <tr
                              v-if="
                                vPropertyContact &&
                                vPropertyContact?.display_name
                              "
                            >
                              <td class="py-0">
                                <label>{{
                                  vPropertyContact?.display_name
                                }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label v-if="vPropertyContact.primary_phone">{{
                                  vPropertyContact.primary_phone
                                }}</label>
                                <em v-else class="text-muted">
                                  no phone number</em
                                >
                              </td>
                            </tr>
                            <tr>
                              <td class="py-0">
                                <label
                                  v-if="
                                    vPropertyContact &&
                                    vPropertyContact.primary_email
                                  "
                                  >{{ vPropertyContact.primary_email }}</label
                                >
                                <em v-else class="text-muted"> no email</em>
                              </td>
                            </tr>
                          </template>
                          <template v-else-if="vCustomerId">
                            <p class="mb-0 red--text">
                              Please select contact person
                            </p>
                          </template>
                          <template v-else>
                            <tr>
                              <td class="font-weight-600 pt-0">
                                <p class="mb-0 red--text">
                                  Please select company
                                </p>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-container>
              </v-col>
              <v-col cols="5" class="pb-0">
                <v-container fluid>
                  <table class="width-100">
                    <tr>
                      <td colspan="3" class="font-weight-700 font-size-16 pb-0">
                        Opportunity details
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0 pt-0" width="200">
                        Opportunity number
                      </td>
                      <td
                        class="font-weight-700 font-size-16 pb-0 pt-0"
                        width="150"
                        :colspan="isUpdateMode ? 2 : 1"
                      >
                        <v-skeleton-loader
                          v-if="pageLoading"
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                        <template v-else>{{
                          opportunityCreate.barcode
                        }}</template>
                      </td>
                      <td v-if="!isUpdateMode" class="font-size-16 pb-0 pt-0">
                        <v-btn
                          text
                          small
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="mx-2 custom-bold-button"
                          v-on:click="barcodeDialog = true"
                          color="cyan"
                        >
                          Change
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td width="200">
                        <label class="font-size-16 pb-0">Start Date </label>
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Start Date *"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker :pageLoading="pageLoading" solo
                        :placeholder="'Start Date *'" :default-date="
                        opportunityCreate.start_date "
                        v-model="opportunityCreate.start_date" mandatory: true
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="200">
                        <label class="font-size-16 pb-0">End Date</label>
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="End Date"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker :pageLoading="pageLoading" solo
                        :placeholder="'End Date'" :default-date="
                        opportunityCreate.end_date "
                        :min-date="opportunityCreate.started_at"
                        v-model="opportunityCreate.end_date" mandatory: true />
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Customer Purchase Order
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Customer Purchase Order"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <TextField
                          id="sap_cust"
                          dense
                          filled
                          placeholder="Customer Purchase Order"
                          solo
                          flat
                          counter="50"
                          v-model="opportunityCreate.purchase_order"
                          color="cyan"
                          :maxlength="50"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-0" width="200">Reference #</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Reference #"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <TextField
                          id="sap_cust"
                          dense
                          filled
                          placeholder="Reference #"
                          solo
                          flat
                          counter="50"
                          v-model="opportunityCreate.reference"
                          color="cyan"
                          :maxlength="50"
                        />
                      </td>
                    </tr>

                    <tr v-if="false">
                      <td class="font-size-16 pb-2" width="200">
                        Project Value
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Project Value"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          type="number"
                          v-on:keypress="
                            limitDecimal(
                              $event,
                              opportunityCreate.project_value
                            )
                          "
                          v-on:keyup="projectValue"
                          v-model="opportunityCreate.project_value"
                          v-model.trim.number.lazy="
                            opportunityCreate.project_value
                          "
                          label="Project Value"
                          :rules="[
                            validateRules.minLength(
                              opportunityCreate.project_value,
                              'Project Value',
                              1
                            ),
                            validateRules.maxLength(
                              opportunityCreate.project_value,
                              'Project Value',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Opportunity Budget
                      </td>
                      <td
                        colspan="2"
                        v-on:keyup="projectValue"
                        class="pb-0"
                        content="Opportunity Budget"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          type="number"
                          v-on:keypress="
                            limitDecimal($event, opportunityCreate.budget)
                          "
                          v-model="opportunityCreate.budget"
                          v-model.trim.number.lazy="opportunityCreate.budget"
                          label="Opportunity Budget"
                          :rules="[
                            validateRules.minLength(
                              opportunityCreate.budget,
                              'Opportunity Budget',
                              1
                            ),
                            validateRules.maxLength(
                              opportunityCreate.budget,
                              'Opportunity Budget',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">Managers</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Managers"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="managersList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="opportunityCreate.managers"
                          label="Managers"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.full_name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ opportunityCreate.managers.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Team Members
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Team Members"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="memberList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="opportunityCreate.members"
                          label="Members"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.full_name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ opportunityCreate.members.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">Lead Source</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Lead Source"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="sorceList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="opportunityCreate.lead_source"
                          label="Source"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                        >
                          <template v-slot:selection="{ item, index }">
                            <span>{{ item }}</span>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ opportunityCreate.lead_source.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Source Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                  </table>
                </v-container>
              </v-col>

              <v-col cols="12" style="padding: 20px !important">
                <DocumnetList
                  v-model="opportunityCreate.opportunity_attachments"
                  :document-type="2"
                >
                </DocumnetList>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>
      <address-select
        v-if="propertyDialog"
        :dialog="propertyDialog"
        v-on:close="propertyDialog = false"
        :type="propertyType"
        :label="propertyLabel"
      />
      <project-select
        :dialog="reCustomerDialog"
        @close="closeCustomerDialog"
        @success="getOptions()"
      />
      <BarcodeSetting
        @update:barcode-setting="handleBarcodeSetting"
        endpoint="opportunity"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>

      <AddProjectDialog
        v-if="addProjectDialog"
        :dialog="addProjectDialog"
        v-on:close="addProjectDialog = false"
        v-on:success="getOptions()"
      />
      <person-select
        v-if="contactDialog"
        :dialog="contactDialog"
        v-on:close="contactDialog = false"
        :type="contactType"
      />
      <!-- <AddProjectDialog :key="`quotation-customer-create-${dialog_key}`" /> -->
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import AddressSelect from "@/view/components/AddressSelect.vue";

import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
import DocumnetList from "@/view/pages/DocumnetList";
import AddProjectDialog from "@/view/components/AddProjectDialog.vue";
import { mapGetters } from "vuex";
import {
  PUT,
  GET,
  POST,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import {
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
} from "@/core/services/store/customer.module";
import {
  RESET_STATE,
  RESET_CREATE_STATE,
  SET_PROJECT,
  CLEAR_PROJECT,
  SET_PROPERTY,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY_CONTACT,
  SET_CUSTOMER,
} from "@/core/services/store/visit.module";
import { ErrorEventBus } from "@/core/lib/message.lib";
import ProjectSelect from "@/view/components/ProjectSelect.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";

import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import ObjectPath from "object-path";
import PersonSelect from "@/view/components/PersonSelect.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "opportunity-create",
  title: "Create Opportunity",
  data() {
    return {
      created: false,
      list: [],
      contactDialog: false,
      contactType: null,
      propertyType: null,
      propertyLabel: null,
      opportunity: 0,
      addProjectDialog: false,
      manageBankNameDialog: false,
      manageInsuranaceCompanyDialog: false,
      customerPropertyDialog: false,
      propertyDialog: false,
      customerPersonDialog: false,
      duplicate: 0,
      pageLoading: true,
      isUpdateMode: false,
      bankList: [],
      projectList: [],
      insuranceList: [],
      barcodeDialog: false,
      isDuplicateMode: false,
      changeAttention: false,
      billing_contact_person: new Object(),
      projectObject: new Object(),
      project: 0,
      project_attachments: [],
      property: new Object(),
      property_contact_person: new Object(),
      bank_attachments: [],
      insurance_attachments: [],
      quotation_attachments: [],
      opportunityCreate: new Object({
        name: null,
        description: null,
        project_id: 0,
        project_attachments: [],

        opportunity_attachments: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
        barcode: null,
        started_at: moment().format("YYYY-MM-DD"),
        deadline: moment().add(1, "month").format("YYYY-MM-DD"),
        status: 0,
        customer_purchase_order: null,
        reference: null,
        project_value: null,
        budget: 0,
        members: [],
        managers: [],
        serial_no: null,
        lead_source: null,
        specification: null,
        customer: null,
        site_location: null,
        billing_address: null,
        property_contact_person: null,
        billing_person: null,
      }),
      contactPersonObject: new Object(),
      barcodeSetting: new Object(),
      projectProperty: null,
      memberList: [],

      sorceList: ["Email", "Facebook"],
      managersList: [],
      billing: null,
      billingTypeList: [
        { text: "Fixed Rate", value: 1 },
        { text: "Project Hours", value: 2 },
      ],
    };
  },
  components: {
    CreateUpdateTemplate,
    DatePicker,
    BarcodeSetting,
    DocumnetList,
    AddressSelect,
    TextValidateInput,
    PersonSelect,
    AddProjectDialog,
    TextAreaField,
    "project-select": ProjectSelect,
  },

  watch: {
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
    duplicate(param) {
      if (param > 0) {
        this.getProject(param);
      }
    },
    opportunity(param) {
      if (param > 0) {
        this.getOpportunity(param);
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Opportunity", route: "opportunity" },
          { title: "update" },
        ]);
      }
    },
  },
  methods: {
    handleBarcodeSetting(data) {
      this.opportunityCreate.barcode = data;
      this.opportunityCreate.serial_no = data;
      // console.log(data, "data");
    },
    selectCustomer(e) {
      this.opportunityCreate.customer = e;
      this.$store.commit(SET_CUSTOMER, this.opportunityCreate.customer);
      this.getCustomerOptions();
    },
    getCustomers() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: "customer-list",
          data: { search: this.search },
        })
        .then((response) => {
          this.list = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateJob() {
      if (!this.vProjectId) {
        ErrorEventBus.$emit("update:error", "Select Project");
        return false;
      }
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Company");
        return false;
      }
      const validateStatus = this.$refs.projectForm.validate();

      if (validateStatus) {
        this.onSubmit();
      }
    },
    createProjectDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },

    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;
      this.project = null;

      if (type == "project") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Company.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }

        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },

    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkProject();
    },
    selectCustomerPerson(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkProject();
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },

    closeDialog() {
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    projectValue() {
      if (
        this.opportunityCreate.opportunity_budget >
        (this.opportunityCreate.project_value &&
          this.opportunityCreate.project_value)
      ) {
        this.opportunityCreate.opportunity_budget =
          this.opportunityCreate.project_value;
        return false;
      }
    },

    updateFiles(type, param) {
      if (type == "project") {
        this.opportunityCreate.project_attachments = param;
      }

      if (type == "bank") {
        this.opportunityCreate.bank_attachments = param;
      }

      if (type == "insurance") {
        this.opportunityCreate.insurance_attachments = param;
      }

      if (type == "quotation") {
        this.opportunityCreate.quotation_attachments = param;
      }
    },

    getOpportunity(opportunity) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "opportunity/" + opportunity,
        })
        .then(({ data }) => {
          _this.opportunityCreate.project_id = this.lodash.toSafeInteger(
            data.project_id
          );
          this.$store.commit(SET_PROJECT, data && data.project && data.project);
          this.$store.commit(
            SET_CUSTOMER,
            data && data.customer && data.customer
          );

          _this.opportunityCreate.barcode = data.barcode;
          _this.opportunityCreate.opportunity_name = data.opportunity_name;
          _this.opportunityCreate.description = data.description;
          this.$store.commit(SET_PROPERTY, data.property);
          _this.opportunityCreate.billing_person = data.billing_person;
          _this.opportunityCreate.billing_contact_person =
            data?.billing_person?.id;
          this.$store.commit(
            SET_BILLING_CONTACT,
            _this.opportunityCreate.billing_person
          );
          _this.opportunityCreate.property_person = data.property_person;
          _this.opportunityCreate.property_contact_person =
            data?.property_person?.id;

          this.$store.commit(
            SET_PROPERTY_CONTACT,
            _this.opportunityCreate.property_person
          );
          this.$store.commit(SET_BILLING, data.billing);
          _this.opportunityCreate.customer =
            data && data.customer && data.customer.id;
          _this.opportunityCreate.property =
            data && data.property && data.property.id;
          _this.opportunityCreate.billing =
            data && data.billing && data.billing.id;
          _this.opportunityCreate.purchase_order = data.purchase_order;
          _this.opportunityCreate.reference = data.reference;
          _this.opportunityCreate.project_value = data.project_value;
          _this.opportunityCreate.budget = data.budget;

          _this.opportunityCreate.specification = data.specification;
          _this.opportunityCreate.bank_name = data.bank_name;
          _this.opportunityCreate.bank_amount = data.bank_amount;
          _this.opportunityCreate.bank_remark = data.bank_remark;

          _this.opportunityCreate.insurance_company = data.insurance_company;
          _this.opportunityCreate.insurance_amount = data.insurance_amount;
          _this.opportunityCreate.insurance_remark = data.insurance_remark;

          _this.opportunityCreate.attention = data.attention;
          _this.opportunityCreate.reference = data.reference;
          _this.opportunityCreate.billing_type = data.billing_type;
          _this.opportunityCreate.rate_per_hour = data.rate_per_hour || null;
          _this.opportunityCreate.estimated_hour = data.estimated_hour || null;
          _this.opportunityCreate.started_at = data.started_at;
          _this.opportunityCreate.deadline = data.deadline;
          _this.opportunityCreate.status = data.status;

          if (data.members && data.members.length > 0) {
            _this.opportunityCreate.members = data.members.map((row) => {
              return row.user.id;
            });
          }
          if (data?.managers && data.managers?.length > 0) {
            _this.opportunityCreate.managers = data.managers.map((row) => {
              return row.user.id;
            });
          }
          this.getOptions();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    setStartDate(param) {
      this.opportunityCreate.started_at = param;
    },
    setDeadline(param) {
      this.opportunityCreate.deadline = param;
    },
    getCustomerOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "company/options",
          data: {
            customer: _this.vCustomerId,
            property: _this.projectProperty,
          },
        })
        .then(({ data }) => {
          // _this.opportunityCreate.customer = _this.vCustomerId;
          if (!_this.lodash.isEmpty(data.billing)) {
            _this.billing = data.billing;
            this.$store.commit(SET_BILLING, _this.billing);

            _this.projectBilling = _this.billing.id;
            _this.opportunityCreate.billing = _this.billing.id;
          }

          if (!_this.lodash.isEmpty(data.property)) {
            _this.property = data.property;
            this.$store.commit(SET_PROPERTY, _this.property);

            _this.projectProperty = _this.property.id;
            _this.opportunityCreate.property = _this.property.id;
          }

          if (!_this.lodash.isEmpty(data.property_person)) {
            _this.property_contact_person = data.property_person;
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              _this.property_contact_person
            );
            _this.projectPropertyPerson = _this.property_contact_person.id;
            _this.opportunityCreate.property_contact_person =
              _this.property_contact_person.id;
          }

          if (!_this.lodash.isEmpty(data.billing_person)) {
            _this.billing_contact_person = data.billing_person;

            this.$store.commit(
              SET_BILLING_CONTACT,
              _this.billing_contact_person
            );
            _this.projectBillingPerson = _this.billing_contact_person.id;
            _this.opportunityCreate.billing_contact_person =
              _this.property_contact_person.id;
          }
          // this.getCustomer();
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "opportunity/options",
          data: {
            project:
              ObjectPath.get(this.$route, "query.project") ?? this.vProjectId,
          },
        })
        .then(({ data }) => {
          _this.created = true;
          if (!_this.opportunity) {
            _this.opportunityCreate.barcode = data.barcode;
          }
          _this.barcodeSetting = data.option;
          _this.projectObject = data?.project;
          if (_this.projectObject?.id > 0) {
            this.$store.commit(SET_PROJECT, _this.projectObject);
            this.selectCustomer(this.projectObject?.customer);
            if (!this.projectObject.customer) {
              this.$store.commit(SET_PROPERTY_CONTACT, {});
              this.$store.commit(SET_PROPERTY, {});
              this.$store.commit(SET_BILLING, {});
              this.$store.commit(SET_BILLING_CONTACT, {});
            }
          }
          _this.opportunityCreate.project_id = _this.projectObject?.id;
          _this.memberList = data.members;
          _this.managersList = data?.managers;
          _this.insuranceList = data.insurance;
          _this.bankList = data.banks;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.projectForm.validate()) {
          return false;
        }
        _this.opportunityCreate.customer = _this.vCustomerId;
        _this.opportunityCreate.property_contact_person =
          this.vPropertyContactId;
        _this.opportunityCreate.billing_contact_person = this.vBillingContactId;
        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.formLoading = true;

        let REQUEST_TYPE = POST;
        let REQUEST_URL = "opportunity";

        if (_this.opportunity && _this.opportunity > 0) {
          REQUEST_TYPE = PUT;
          REQUEST_URL = "opportunity/" + _this.opportunity;
        }

        _this.$store
          .dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: _this.opportunityCreate,
          })
          .then(({ data }) => {
            _this.created = true;
            _this.$router.push(
              _this.getDefaultRoute("opportunity.detail", {
                params: { id: data.id },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  destroyed() {
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_PROJECT);
  },
  beforeCreate() {
    this.$store.dispatch(CLEAR_PROJECT);
    this.$store.dispatch(RESET_CREATE_STATE);
  },
  created() {
    const _this = this;
    _this.opportunity = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicate = _this.lodash.toSafeInteger(_this.$route.query.duplicate);
    _this.customer = _this.lodash.toSafeInteger(_this.$route.query.customer);
    _this.projectProperty = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );

    _this.quotation = _this.lodash.toSafeInteger(_this.$route.query.quotation);
    if (_this.quotation > 0) {
      _this.getQuotation();
    }
    _this.opportunityCreate.project_id = _this.vProjectId;
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.getCustomers();

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Opportunity", route: "opportunity" },
      { title: "Create" },
    ]);

    this.$nextTick(() => {});
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.created) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  computed: {
    ...mapGetters([
      "vProject",
      "vProjectId",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "reCustomerDialog",
    ]),
    billingTypeLabel() {
      if (this.opportunityCreate.billing_type == 1) {
        return "Total Rate";
      } else if (this.opportunityCreate.billing_type == 2) {
        return "Rate Per Hour";
      }
      return null;
    },
    defaultDeadline() {
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
};
</script>
