<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout class="px-4">
        <v-flex> Create new Project </v-flex>
        <v-flex class="text-right">
          <v-btn
            class="custom-bold-button mr-4"
            depressed
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            v-on:click="onSubmit()"
            color="cyan white--text"
            >Save
          </v-btn>
          <v-btn
            class="custom-bold-button custom-border"
            depressed
            :disabled="formLoading"
            v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-container>
        <v-layout>
          <v-form
            ref="customerForm"
            v-model="formValid"
            lazy-validation
            v-on:submit.stop.prevent="onSubmit"
          >
            <v-row>
              <v-col md="12">
                <div>
                  <label class="font-weight-700 font-size-16 required"
                    >Project Title
                  </label>
                  <TextValidateInput
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :rules="[
                      validateRules.required(
                        projectCreate.name,
                        'Project Name'
                      ),
                      validateRules.maxLength(
                        projectCreate.name,
                        'Project Name',
                        50
                      ),
                    ]"
                    id="display-name"
                    placeholder="Name"
                    counter="50"
                    :validationField="{
                      url_type: 'project',
                      filter_type: 'project',
                      field: 'name',
                    }"
                    :parent-id="projectCreate.id"
                    :current-id="projectCreate.id"
                    show-dropdown
                    v-model="projectCreate.name"
                    hideTopMargin
                  ></TextValidateInput>
                  <!-- <v-text-field
                  v-model.trim="projectCreate.name"
                  dense
                  filled
                  color="cyan"
                  label="Project Name"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                ></v-text-field> -->
                </div>
              </v-col>
              <v-col md="6">
                <div>
                  <label class="font-weight-700 font-size-16"
                    >Start Date
                  </label>
                  <DatePicker solo :placeholder="'Start Date *'" :default-date="
                  projectCreate.started_at " v-model="projectCreate.started_at"
                  mandatory: true />
                </div>
              </v-col>
              <v-col md="6">
                <div>
                  <label class="font-weight-700 font-size-16">End Date </label>
                  <DatePicker solo :placeholder="'End Date'" :default-date="
                  projectCreate.deadline " :min-date="projectCreate.started_at"
                  v-model="projectCreate.deadline" mandatory: true />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-layout>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import { toSafeInteger } from "lodash";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { SET_PROJECT } from "@/core/services/store/visit.module";
import { PUT, POST } from "@/core/services/store/request.module";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "opportunity",
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      pageLoading: false,
      formLoading: false,
      addProjectDialog: false,
      projectCreate: {
        name: null,
        started_at: moment().format("YYYY-MM-DD"),
        deadline: moment().add(1, "month").format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    onSubmit() {
      const _this = this;
      try {
        const formErrors = _this.validateForm(_this.$refs.customerForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }
        _this.formLoading = true;

        let REQUEST_TYPE = POST;
        let REQUEST_URL = "projects";

        if (_this.project && _this.project > 0) {
          REQUEST_TYPE = PUT;
          REQUEST_URL = "projects/" + _this.project;
        }

        _this.$store
          .dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: _this.projectCreate,
          })
          .then(({ data }) => {
            this.$store.commit(SET_PROJECT, data);
            this.$emit("close", true);

            this.$emit("success", true);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    DatePicker,
    TextValidateInput,
  },

  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 150) * 50);
    },
  },
};
</script>
