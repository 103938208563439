var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"common-dialog":_vm.dialog,"dialog-width":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-layout',{staticClass:"px-4"},[_c('v-flex',[_vm._v(" Create new Project ")]),_c('v-flex',{staticClass:"text-right"},[_c('v-btn',{staticClass:"custom-bold-button mr-4",attrs:{"depressed":"","disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan white--text"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Save ")]),_c('v-btn',{staticClass:"custom-bold-button custom-border",attrs:{"depressed":"","disabled":_vm.formLoading},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v("Cancel")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-container',[_c('v-layout',[_c('v-form',{ref:"customerForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16 required"},[_vm._v("Project Title ")]),_c('TextValidateInput',{attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[
                    _vm.validateRules.required(
                      _vm.projectCreate.name,
                      'Project Name'
                    ),
                    _vm.validateRules.maxLength(
                      _vm.projectCreate.name,
                      'Project Name',
                      50
                    ),
                  ],"id":"display-name","placeholder":"Name","counter":"50","validationField":{
                    url_type: 'project',
                    filter_type: 'project',
                    field: 'name',
                  },"parent-id":_vm.projectCreate.id,"current-id":_vm.projectCreate.id,"show-dropdown":"","hideTopMargin":""},model:{value:(_vm.projectCreate.name),callback:function ($$v) {_vm.$set(_vm.projectCreate, "name", $$v)},expression:"projectCreate.name"}})],1)]),_c('v-col',{attrs:{"md":"6"}},[_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Start Date ")]),_c('DatePicker',{attrs:{"solo":"","placeholder":'Start Date *',"default-date":_vm.projectCreate.started_at,"mandatory:":"","true":""},model:{value:(_vm.projectCreate.started_at),callback:function ($$v) {_vm.$set(_vm.projectCreate, "started_at", $$v)},expression:"projectCreate.started_at"}})],1)]),_c('v-col',{attrs:{"md":"6"}},[_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("End Date ")]),_c('DatePicker',{attrs:{"solo":"","placeholder":'End Date',"default-date":_vm.projectCreate.deadline,"min-date":_vm.projectCreate.started_at,"mandatory:":"","true":""},model:{value:(_vm.projectCreate.deadline),callback:function ($$v) {_vm.$set(_vm.projectCreate, "deadline", $$v)},expression:"projectCreate.deadline"}})],1)])],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }